<template>
	<div class="row">
		<div class="col-12">

      <h1 class="titlePage titlePage_textLeft">{{getUserName}}</h1>

			<breadcrumbs></breadcrumbs>


        <button class="btn btn_default" @click="addToSet">Add package to set</button>
        <button class="btn btn_default" @click="create">Save set without any packages</button>

		</div>
	</div>
</template>

<script>
	import breadcrumbs from "./../../parts/breadcrumbs";
	import router from "./../../../router";
	export default {
		name: "MakeSetButtons",
		components: {
			breadcrumbs
		},
		methods: {
			create() {
				this.$store.dispatch('sets/postSets');
			},
			addToSet() {
				router.push({path: '/sets-builder-choice'});
				this.$store.commit('sets/set', {k: 'isSetState', v: false});
				this.$parent.exchangeData = 'selectOptions';
			}
		},

    computed: {
      getUserName() {
        return localStorage.getItem('nameUser');
      },
    }
	}
</script>

<style scoped>

  .btn{
    margin-right: 20px;
  }


  @media screen and (max-width: 450px){
    .btn{
      margin: 10px 0;
    }
  }
</style>
